import EventBus from '@/util/EventBus'
import getEnv from '@/util/env'

export default {
  name: 'MessagePreview',
  props: [
    'campaign',
    'preview',
    'contactsPhone',
    'fields',
    'availableForms',
    'availableUrls',
    'shortUrlDomain',
    'regex',
    'canUseCustomSender',
  ],
  data: function () {
    return {
      generatedUrls: [],
    }
  },
  computed: {
    senderPreview: function () {
      const sender = this.campaign.sendings[0].channel.sender
      if (!sender) {
        return this.$t('Remitente').toString()
      } else if (sender === 'Test' && !this.canUseCustomSender) {
        return this.$t('Automático').toString()
      }
      return sender
    },
    messagePreview: function () {
      const previewUrl = this.shortUrlDomain + '/sample'
      if (!this.campaign.sendings[0].channel.message) {
        return this.$t('Contenido del SMS').toString()
      }
      if (this.preview.contactsPhone.length > 0) {
        if (this.preview.contactPreview === 0) {
          this.preview.contactPreview = this.preview.contactsPhone[0]
        }
        return this.changeSmsPreview(this.preview.contactPreview)
      }

      let message = this.campaign.sendings[0].channel.message

      const matches = message.match(/({FORM_[0-9]+})/g)
      if (matches) {
        matches.forEach(match => {
          const formId = match.match(/([0-9]+)/g)
          message = message.replace('{FORM_' + formId + '}', '<span data-form-id="' + formId + '" class="sample-url form-url">' + previewUrl + '</span>')
        })
      }

      const urlMatches = message.match(/({URL_[0-9]+})/g)
      if (urlMatches) {
        urlMatches.forEach(match => {
          const urlId = match.match(/([0-9]+)/g)
          const url = this.availableUrls[urlId]?.url_preview
          message = message.replace('{URL_' + urlId + '}', '<span data-url-id="' + urlId + '"class="sample-url url-url"><a target="_blank" href="' + url + '">' + previewUrl + '</a></span>')
        })
      }

      const unsubMatches = message.match(/({UNSUB_URL_[0-9]+})/g)
      if (unsubMatches) {
        unsubMatches.forEach(match => {
          const urlId = match.match(/([0-9]+)/g)
          message = message.replace('{UNSUB_URL_' + urlId + '}', '<span data-url-id="' + urlId + '" class="sample-url unsub-url-with-id">https://nrs.so/sample</span>')
        })
      }

      message = this.replaceUrlFields(message)
      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">' + previewUrl + '</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">' + previewUrl + '</span>')

      return message
    },
    datePreview: function () {
      if (this.preview.contactsPhone.length > 0) {
        const contactFields = this.preview.contactsFieldsValue[this.preview.contactsPhone[0]]
        let field = this.campaign.sendings[0].automaticConfig.conditions.field
        if (this.campaign.recipientsSource !== 'contacts') {
          const index = parseInt(field) + 1
          field = 'COL' + index
        }
        return contactFields[field]
      }
      return ''
    },
  },
  created () {
  },
  methods: {
    showLanding () {
      this.$emit('showLanding')
    },
    changeSmsPreview (index) {
      const object = this.preview.contactsFieldsValue[index]
      const contactFields = []
      for (const index in object) {
        contactFields[index] = object[index]
      }

      let message = this.campaign.sendings[0].channel.message
      message = this.replaceUrlFields(message)

      for (let i = 0; i < this.fields.currentFieldsMessage.length; i++) {
        const field = this.fields.currentFieldsMessage[i]
        const fieldValue = contactFields[field] ? contactFields[field] : this.$t('Sin datos')
        message = message.replace('{' + this.fields.currentFieldsMessage[i] + '}', fieldValue)
      }

      const unsubMatches = message.match(/({UNSUB_URL_[0-9]+})/g)
      if (unsubMatches) {
        unsubMatches.forEach(match => {
          const urlId = match.match(/([0-9]+)/g)
          message = message.replace('{UNSUB_URL_' + urlId + '}', '<span data-url-id="' + urlId + '" class="sample-url unsub-url-with-id">https://nrs.so/sample</span>')
        })
      }

      const previewUrl = this.shortUrlDomain + '/sample'
      message = message.replaceAll('{LAND_URL}', '<span class="sample-url land-url">' + previewUrl + '</span>')
      message = message.replaceAll('{UNSUB_URL}', '<span class="sample-url unsub-url">' + previewUrl + '</span>')
      return message
    },
    // eslint-disable-next-line complexity
    replaceUrlFields (message) {
      for (let i = 0; i < this.fields.currentUrlFieldsMessage.length; i++) {
        const explodeUrlField = this.fields.currentUrlFieldsMessage[i].split('_')
        let value = ''
        switch (explodeUrlField[0]) {
          case 'FORM':
            value = this.generateSampleUrl(null, 'form-url', `data-form-id="${ explodeUrlField[1] }"`)
            break
          case 'UNSUB':
            value = this.generateSampleUrl(
              null,
              explodeUrlField[2] ? 'unsub-url-with-id' : 'unsub-url',
              explodeUrlField[2] ? `data-url-id="${ explodeUrlField[2] }"` : '',
            )
            break
          case 'ATTACHMENT':
            value = this.generateSampleUrl(this.getAttachmentUrl(explodeUrlField[1]))
            break
          case 'URL':
            value = this.generateSampleUrl(this.availableUrls[explodeUrlField[1]]?.url_preview)
            break
          }
        if (!value) {
          this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]] || this.generateSampleUrl()
          value = this.generatedUrls[this.fields.currentUrlFieldsMessage[i]]
        }
        if (['LAND_URL', 'UNSUB_URL'].indexOf(this.fields.currentUrlFieldsMessage[i]) < 0) {
          message = message.replace('{' + this.fields.currentUrlFieldsMessage[i] + '}', value)
        }
      }
      return message
    },
    getAttachmentUrl (field) {
      // eslint-disable-next-line no-case-declarations
      let attachment = this.campaign.sendings[0].channel.attachments.filter((attachmentIn) => {
        return parseInt(field) === attachmentIn.id
      })

      attachment = attachment[0]
      const url = attachment ? getEnv('VUE_APP_ASSETS_URL') + '' + attachment.url : null

      return url
    },
    generateRandomShortUrl (url = null) {
      const randStr = (Math.random() + 1).toString(36).substring(6)
      let randUrl = `${this.shortUrlDomain}/${randStr}`
      if (url) {
        randUrl = `<a target="_blank" href="${ url }">${ randUrl }</a>`
      }
      return `<span class="sample-url">${ randUrl }</span>`
    },
    generateSampleUrl (url = null, addClass = null, objectId = null) {
      let sampleUrl = this.shortUrlDomain + '/sample'
      if (url) {
        sampleUrl = `<a target="_blank" href="${ url }">${ sampleUrl }</a>`
      }
      return `<span ${ objectId } class="sample-url ${addClass}">${ sampleUrl }</span>`
    },
    replaceUrls (message) {
      const allMatches = Array.from(message.matchAll(/\{URL_(.+?)\}/gmi))
      if (allMatches) {
        allMatches.forEach(match => {
          const [form, idform] = [...match]
          const url = this.availableUrls[idform]?.url_preview || null
          this.generatedUrls[form] = this.generatedUrls[form] || this.generateSampleUrl(url)
          message = message.replace(form, this.generatedUrls[form])
        })
      }
      return message
    },
    sendTestEvent () {
      EventBus.$emit('send-test-event')
    },
    handleClick (e) {
      if (e.target.matches('.land-url')) {
        this.$emit('showLanding')
      }

      if (e.target.matches('.form-url')) {
        const formId = e.target.getAttribute('data-form-id')
        this.$emit('showForm', formId)
      }

      if (e.target.matches('.url-url')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUrl', urlId)
      }

      if (e.target.matches('.unsub-url')) {
        this.$emit('showUnsubscribe', undefined)
      }

      if (e.target.matches('.unsub-url-with-id')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUnsubscribe', urlId)
      }
    },
  },
}
